@import "../../mixins.scss";

.contact-section {
  background: url("../../assets/images/contact-section-bg.webp") center center
    no-repeat;
  background-size: cover;
  width: 100%;
  height: 500px;
  padding: 0 10%;
  @include flex($jc: flex-start);
  margin: 90px 0 120px 0;
  position: relative;

  .content {
    width: 33%;
    z-index: 2;
    h3 {
      @include heading2();
      color: var(--secondary-text);
      text-transform: uppercase;
    }
    p {
      @include para();
      color: var(--secondary-text);
      margin: 15px 0;
    }

    form {
      input {
        display: block;
        background: transparent;
        width: 100%;
        margin: 15px 0;
        padding: 10px 5px;
        border: none;
        border-bottom: 1px solid #d9d9d9;
        @include para();
        font-size: 1.2rem;
        &:focus {
          outline: none;
        }
      }
      button {
        @include flex;
        border: none;
        transition: all 0.3s;
        padding: 10px 0;
        cursor: pointer;
        transition: all 0.3s;
        width: 160px;
        background-color: var(--primary-color);
        border: none;
        border-radius: 1px;
        @include para();
        color: var(--primary-text);
        text-transform: capitalize;
        text-transform: capitalize;

        &:hover {
          background-color: var(--secondary-text);
          color: var(--primary-text);
          font-weight: 600;
        }
      }
    }
  }

  img[alt="spikes-icon"] {
    position: absolute;
    top: -35px;
    left: 25%;
    height: 90px;
    width: auto;
    z-index: 2;
  }

  @media (max-width: 800px) {
    padding: 0;
    .content {
      width: 50%;
      padding-left: 5%;
      h3 {
        color: var(--primary-text);
      }
      p {
        color: var(--primary-text);
      }
      form {
        input {
          border-bottom: 1px solid #fff;
          color: var(--primary-text);

          &::placeholder {
            color: var(--primary-text);
          }
        }
      }
    }

    &:after {
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.65);
      z-index: 1;
    }
  }
  @media (max-width: 500px) {
    .content {
      width: 90%;
    }
  }
}
