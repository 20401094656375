@import "../../mixins.scss";

.footer {
  padding: 30px 10% 60px 10%;
  position: relative;
  bottom: 0;

  .newsletter {
    margin: 0 auto 60px auto;
    h3 {
      @include heading2();
      color: #333;
      text-transform: uppercase;
      @include flex();
      flex-direction: column;
    }
    .newsletter-form {
      margin: auto;
      width: 100%;
      text-align: center;
      @include flex();
      margin-top: 10px;
      input {
        padding: 12px 5px;
        border: 1px solid #999;
        width: 35%;
        @include para();
        font-size: 1.2rem;
        color: #333;
      }
      button {
        padding: 9.5px 22px;
        @include para();
        color: #333;
        background: transparent;
        border: 1px solid #999;
        cursor: pointer;
        transform: var(--basic) !important;
        &:hover {
          background-color: #000;
          color: var(--primary-text);
        }
      }
    }
  }

  .main-footer {
    @include flex($ai: flex-start);
    .company {
      flex: 1.7;
      margin-top: -30px;
      img[alt="logo"] {
        width: 240px;
        height: auto;
      }
      p {
        @include para();
        color: #333;
        margin: 10px 0 15px 0;
      }
      .social-links {
        @include flex($jc: flex-start, $ai: flex-start);
        li {
          @include flex();
          margin: 0 5px;
          a {
            padding: 8px;
            height: 40px;
            box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
            background: #fff;
            @include flex();
            &:first-child {
              margin: 0;
            }
            img {
              width: 25px;
              object-fit: cover;
              height: auto;
            }
          }
        }
      }
    }

    .quick-links {
      flex: 0.6;
      padding-left: 15px;
      h3 {
        @include heading2();
        color: #333;
        text-transform: uppercase;
        font-size: 2rem;
        font-weight: 500;
        margin-bottom: 15px;
      }
      ul {
        li {
          @include flex($jc: flex-start);
          a {
            @include para();
            color: #333;
          }
        }
      }
    }

    .opening-hours {
      flex: 0.9;
      padding-left: 15px;
      h3 {
        @include heading2();
        color: #333;
        text-transform: uppercase;
        font-size: 2rem;
        font-weight: 500;
        margin-bottom: 15px;
      }
      p {
        @include para();
        color: #333;
      }
    }

    .contact-us {
      flex: 1.4;
      padding-left: 15px;
      h3 {
        @include heading2();
        color: #333;
        text-transform: uppercase;
        font-size: 2rem;
        font-weight: 500;
        margin-bottom: 15px;
      }
      div,
      a {
        @include flex($jc: flex-start, $ai: flex-start);
        margin: 10px 0;
        img {
          width: 20px;
          height: auto;
          margin-right: 10px;
        }
        p,
        a {
          @include para();
          color: #333;
        }
      }
    }
  }

  img[alt="right-quad-icon"] {
    width: 100px;
    height: auto;
    position: absolute;
    top: 30px;
    right: 25px;
  }
  img[alt="left-quad-icon"] {
    width: 60px;
    height: auto;
    position: absolute;
    bottom: 30px;
    left: 25px;
  }

  @media (max-width: 800px) {
    padding: 30px 5% 60px 5%;
    .newsletter {
      .newsletter-form {
        input {
          width: 55%;
        }
      }
    }
    .main-footer {
      flex-direction: column;
      .company,
      .quick-links,
      .contact-us,
      .opening-hours {
        margin-top: 20px;
      }
    }

    img[alt="right-quad-icon"] {
      width: 70px;
      top: 160px;
      right: 25px;
    }
    img[alt="left-quad-icon"] {
      width: 30px;
    }
  }
}
.copyright {
  @include flex();
  @include para($fw: 600);
  color: var(--primary-text);
  height: 60px;
  background-color: var(--primary-color);
  width: 100%;
  text-align: center;
}
