@import "../../mixins.scss";

.our-story {
  .massage-redirect-section {
    padding: 0 10% 0 0;
    @include flex($jc: space-between);
    position: relative;
    .image {
      flex: 1.3;
      transition: var(--advance);
      &:hover {
        transform: translateX(-15px);
      }
    }
    .text {
      flex: 1;
      justify-self: flex-end;
      padding-left: 20px;
      a {
        @include para($fw: 500);
        color: var(--secondary-text);
        text-decoration: underline;
        font-size: 3rem;
        margin: auto;
        transition: var(--advance);
        &:hover {
          color: var(--primary-color);
        }
      }
    }
    img[alt="spikes-icon"] {
      position: absolute;
      bottom: -50px;
      right: 35px;
      width: 80px;
      height: auto;
    }
  }

  .section-3 {
    .bg-pattern {
      background: url("../../assets/images/leaves-pattern.svg") center center
        no-repeat;
      background-size: cover;
      position: absolute;
      top: 60px;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: -5;
    }

    img[alt="quad-icon"] {
      position: absolute;
      left: 60px;
      bottom: 15px;
      width: 60px;
      height: auto;
    }

    .left {
      img[alt="spikes-icon"] {
        position: absolute;
        top: 15px;
        left: -100px;
        width: 80px;
        height: auto;
      }
    }
  }

  @media (max-width: 800px) {
    .massage-redirect-section {
      flex-direction: column;
      margin: 40px 0;
      .image {
        margin-bottom: 35px;
      }
    }

    .section-3{
      img[alt="quad-icon"] {
        left: 90%;
        bottom: -85px;
        width: 50px;
      }
    }
  }
}
