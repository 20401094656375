@import "../../mixins.scss";

.service-card {
  min-width: 340px;
  max-width: 340px;
  min-height: 410px;
  max-height: 410px;
  padding: 30px 20px;
  background: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  text-align: center;
  position: relative;
  border-radius: 3px;
  cursor: pointer;
  transition: all 0.3s;
  margin: 30px 15px;
  img {
    width: 120px;
    transition: all 0.3s;
  }
  h4 {
    @include heading2();
    color: var(--secondary-text);
    transition: all 0.3s;
  }
  .service-desc {
    @include para();
    font-size: 1.8rem;
    letter-spacing: 1px;
    line-height: 29px;
    color: var(--secondary-text);
    margin-top: 5px;
    transition: all 0.3s;
  }
  .price {
    @include flex();
    @include heading2();
    font-size: 3rem;
    color: var(--primary-text);
    position: absolute;
    bottom: -25px;
    left: 0;
    right: 0;
    background: var(--primary-color);
    width: 140px;
    height: 50px;
    border-radius: 7px;
    margin: auto;
    transition: all 0.3s;
  }

  &:hover {
    background: var(--primary-color);
    img{
        transform: scale(1.1);
    }
    h4,
    .service-desc {
      color: var(--primary-text);
    }
    .price {
      background: var(--primary-text);
      color: var(--primary-color);
      border: 1px solid var(--primary-color);
    }
  }
}
