@import "../../mixins.scss";

.hero {
  background: url(../../assets/images/hero-bg.webp) center center no-repeat;
  background-size: cover;
  // width: 100%;
  // height: 770px;
  aspect-ratio: 16/9;
  @include flex($jc: flex-start);
  margin-bottom: 80px;
  .content {
    width: 50%;
    margin-left: 100px;
    margin-top: -120px;
    h1 {
      @include heading1();
      color: var(--primary-text);
      text-transform: uppercase;
      line-height: 100px;
    }
    h2 {
      @include heading1();
      color: var(--primary-text);
      text-transform: uppercase;
      line-height: 100px;
    }
    p {
      font-weight: 400;
      font-size: 3rem;
      font-family: "Jost", sans-serif;
      color: var(--primary-text);
      text-transform: capitalize;
      line-height: 40px;
      margin: 20px 0 30px 0;
    }
    button {
      @include button();
    }
    .hero-slider {
      padding-bottom: 60px;
      .swiper-pagination {
        left: 8px !important;
        bottom: 0px !important;
        @include flex();
        width: unset;

        .swiper-pagination-bullet {
          background-color: #fff;
          opacity: 1;
          height: 7px;
          width: 7px;
          transition: var(--advance);
          margin: 0 2px;

          &:hover {
            transform: scale(1.1);
          }
        }
      }

      .swiper-pagination-bullet-active {
        background-color: var(--primary-color) !important;
        height: 19px !important;
        width: 19px !important;
      }
    }
  }

  @media (max-width: 1130px) {
    .content {
      margin-top: -40px;
      width: 55%;
      margin-left: 40px;
      h1 {
        line-height: 60px;
      }
      h2 {
        line-height: 60px;
      }
      p {
        font-size: 2.2rem;
        line-height: 28px;
        margin: 10px 0 20px 0;
      }
    }
  }

  @media (max-width: 700px) {
    margin-bottom: 50px;
    .content {
      margin-top: 15px;
      width: 65%;
      margin-left: 15px;
      h1 {
        line-height: 35px;
      }
      h2 {
        line-height: 35px;
      }
      p {
        font-size: 1.4rem;
        line-height: 18px;
        margin: 10px 0 20px 0;
      }

      button {
        @include button($w: 80px);
        margin-top: 7px;
        a {
          padding: 5px 0;
          font-size: 1.2rem;
        }
      }

      .hero-slider {
        padding-bottom: 60px;
        .swiper-pagination {
          left: 8px !important;
          bottom: 25px !important;
        }
      }
    }
  }
}
