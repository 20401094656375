@import "../../mixins.scss";

.common-section{
    background: url("../../assets/images/common-bg.webp") center center no-repeat;
    background-size: cover;
    @include flex();
    width: 100%;
    height: 550px;
    margin-bottom: 80px;
    h4{
        @include heading1();
        color: var(--primary-text);
        text-transform: uppercase;
    }   
    h1{
        @include heading1();
        color: var(--primary-text);
        text-transform: uppercase;
    }  

    @media (max-width:550px) {
        height: 300px;
    }
}