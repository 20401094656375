@import "../../mixins.scss";

.info-nav {
  width: 100%;
  text-align: center;
  background-color: #000;
  z-index: 10;

  p {
    color: var(--primary-text);
    padding: 12px 0;
    font-size: 1.8rem;
    font-weight: 600;
    font-family: "Jost", sans-serif;;
  }

  @media (max-width: 450px) {
    p{
      font-size: 1.4rem;
    }
  }
}
