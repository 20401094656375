@import "../../mixins.scss";

.nail-insights{
    padding: 0 10%;
    position: relative;

    .bg-pattern {
        background: url("../../assets/images/leaves-pattern.svg") center center
          no-repeat;
        background-size: cover;
        position: absolute;
        top: -50px;
        left: 0;
        height: 850px;
        width: 100%;
        z-index: -5;
      }

    h3{
        @include heading2();
        color: var(--secondary-text);
        text-align: center;
        text-transform: uppercase;
    }
    h1{
        @include heading2();
        color: var(--secondary-text);
        text-align: center;
        text-transform: uppercase;
    }

    .all-blogs{
        margin-bottom: 60px;
        width: 100%;
        @include flex();
        flex-wrap: wrap;
        .blog-card{
            min-width: 310px;
            max-width: 310px;
            margin: 15px 20px;
            img{
                height: auto;
                width: 310px;
            }
            .date{
                @include para($fw: 600);
                color: var(--secondary-text);
                span{
                    color: var(--primary-color);
                    @include para($fw: 600);
                }
            }

            .title{
                @include heading2();
                color: var(--secondary-text);
                line-height: 35px;
                font-size: 2.8rem;
                margin: 5px 0;
            }

            .short-desc{
                @include para();
                color: var(--secondary-text);
            }

            a{
                background-color: #EF512F;
                margin: 15px 0 0 0;
                box-shadow: 0px 0px 13px #EF512F;
                height: 40px;
                width: 40px;
                border-radius: 100%;
                @include flex();
                transition: var(--advance);
                img{
                    height: auto;
                    width: 20px;
                }
                &:hover{
                    transform: scale(1.05);
                }
            }
        }
    }
}