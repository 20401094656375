@import "../../mixins.scss";

.services {
  .s-section {
    position: relative;
    .left {
      .icon {
        width: 200px;
        top: 70%;
        right: 0;
      }
    }
    .right {
      .desc {
        margin: 15px 0;
      }
      .bottom {
        p {
          @include para($fw: 600);
          text-decoration: underline;
        }
      }
    }

    .bg-pattern {
      background: url("../../assets/images/leaves-pattern.svg") center center
        no-repeat;
      background-size: cover;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: -5;
    }

    img[alt="quad-icon"] {
      position: absolute;
      left: 30px;
      bottom: -85px;
      width: 60px;
      height: auto;
    }

    img[alt="spikes-icon"] {
      position: absolute;
      top: -55px;
      right: 80px;
      width: 80px;
      height: auto;
    }
  }

  .manicure-services {
    position: relative;
    margin: 60px 0 90px 0;
    h3 {
      @include heading2();
      font-weight: 500;
      color: var(--secondary-text);
      display: block !important;
      width: 100%;
      text-align: center;
    }
    h2{
      @include heading2();
      font-weight: 500;
      color: var(--secondary-text);
      display: block !important;
      width: 100%;
      text-align: center;
    }
    padding: 0 5%;
    @include flex();
    flex-wrap: wrap;

    .bg-pattern {
      background: url("../../assets/images/leaves-pattern.svg") center center
        no-repeat;
      background-size: cover;
      position: absolute;
      top: 50px;
      left: 0;
      height: 850px;
      width: 100%;
      z-index: -5;
    }

    .bg-pattern2 {
      background: url("../../assets/images/leaves-pattern.svg") center center
        no-repeat;
      background-size: cover;
      position: absolute;
      top: 850px;
      left: 0;
      height: 850px;
      width: 100%;
      z-index: -5;
    }

    img[alt="quad-icon"] {
      position: absolute;
      left: 30px;
      bottom: 650px;
      width: 60px;
      height: auto;
    }

    img[alt="spikes-icon"] {
      position: absolute;
      top: 250px;
      right: 20px;
      width: 80px;
      height: auto;
    }
  }

  .pedicure-services {
    background: url("../../assets/images/pedicure-bg.jpg") center center
      no-repeat;
    background-size: cover;
    height: 370px;
    margin-bottom: 350px;
    .content {
      padding: 90px 5% 0 5%;
      @include flex();
      flex-wrap: wrap;
      h3 {
        @include heading2();
        font-weight: 500;
        color: var(--primary-text);
        text-align: center;
        width: 100%;
      }
      h2 {
        @include heading2();
        font-weight: 500;
        color: var(--primary-text);
        text-align: center;
        width: 100%;
      }
    }
  }

  .additional-services {
    margin: 60px 0 90px 0;
    position: relative;
    h3 {
      @include heading2();
      font-weight: 500;
      color: var(--secondary-text);
      display: block !important;
      width: 100%;
      text-align: center;
      margin-bottom: 10px;
    }
    h2 {
      @include heading2();
      font-weight: 500;
      color: var(--secondary-text);
      display: block !important;
      width: 100%;
      text-align: center;
      margin-bottom: 10px;
    }
    padding: 0 5%;
    @include flex();
    flex-wrap: wrap;

    .bg-pattern {
      background: url("../../assets/images/leaves-pattern.svg") center center
        no-repeat;
      background-size: cover;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: -5;
    }

    img[alt="quad-icon"] {
      position: absolute;
      top: -55px;
      right: 80px;
      width: 80px;
      height: auto;
    }
  }

  @media (max-width: 1238px) {
    .pedicure-services{
      margin-bottom: 800px;
    }
  }

  @media (max-width: 824px) {
    .pedicure-services{
      margin-bottom: 1250px;
    }
  }

  @media (max-width: 600px) {
    .manicure-services{
      img[alt="quad-icon"] {
        bottom: 800px;
        width: 50px;
      }
  
      img[alt="spikes-icon"] {
        top: 550px;
        width: 60px;
      }
    }

    .additional-services{
      img[alt="quad-icon"] {
        top: -80px;
        right: 10px;
        width: 60px;
      }
    }
  }
}
