@import "../../mixins.scss";

.cta-section{
    padding: 0 10% 0 0;
    background: url("../../assets/images/cta-bg.webp") center center no-repeat;
    background-size: cover;
    width: 100%;
    height: 450px;
    @include flex($ai: flex-end);
    margin: 200px 0 120px 0;
    .left{
        img{
            width: 90%;
        }
    }
    .right{
        width: 40%;
        align-self: center;
        h3{
            @include heading1();
            font-size: 5rem;
            color: var(--primary-text)
        }
        button{
            @include button();
        }
    }

    @media (max-width: 1100px) {
        height: 350px;
        .right{
            h3{
                font-size: 3rem;
            }
        }
    }
    @media (max-width: 780px) {
        height: 250px;
        margin: 100px 0 90px 0;
        .right{
            h3{
                font-size: 2rem;
            }
            button{
                @include button($w: 80px);
                margin-top: 7px;
                a{
                    padding: 5px 0;
                    font-size: 1.2rem;
                }
            }
        }
    }
    @media (max-width: 500px) {
        height: 120px;
        .right{
            h3{
                font-size: 1.2rem;
            }
        }
    }
}