@import "../../mixins.scss";

.section {
  padding: 0 10%;
  margin: 0 auto 60px auto;
  @include flex($jc: space-between);
  position: relative;
  flex-wrap: wrap;

  .left {
    flex: 1;
    padding-right: 60px;
    position: relative;

    .icon {
      position: absolute;
      top: 55%;
      right: 15px;
      height: 160px;
      width: auto;
    }
  }

  .right {
    flex: 1;
    position: relative;
    .label {
      @include label();
      color: var(--primary-color);
    }

    .title {
      @include heading2();
      color: var(--secondary-text);
      line-height: 52px;
      text-transform: uppercase;
    }

    .desc {
      @include para();
      color: var(--secondary-text);
      line-height: 30px;
      span {
        @include para();
        color: var(--secondary-text);
        line-height: 30px;
      }
    }
  }

  .leaf-icon-right {
    position: absolute;
    top: 0;
    height: 700px;
    width: auto;
    right: 0;
    z-index: -5;
  }

  .leaf-icon-left {
    position: absolute;
    top: 0;
    height: 1000px;
    width: auto;
    left: 0;
    z-index: -5;
  }

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: flex-start;
    .left {
      margin-bottom: 30px;
      padding-right: 0;
      .icon {
        right: -30px;
        height: 90px;
      }
    }

    .right {
      .title {
        line-height: 40px;
      }
      .desc {
        line-height: 26px;
        margin: 15px 0;
      }
    }
  }
}

.section-1 {
  .right {
    .bottom {
      @include flex($jc: flex-start);

      img {
        width: 140px;
        margin-left: 10px;
      }

      button {
        @include button($bgc: #000);
        border: 1px solid #000;
        border-radius: 1px;
        margin-top: 10px;
      }
    }
  }

  .left-icon-right {
    top: 100px;
  }

  img[alt="spike-left"] {
    height: 80px;
    width: auto;
    position: absolute;
    left: 30px;
    bottom: 0;
  }
  img[alt="spike-right"] {
    height: 80px;
    width: auto;
    position: absolute;
    right: 30px;
    top: 0;
  }

  @media (max-width: 800px) {
    img[alt="spike-left"] {
      height: 50px;
      left: 5px;
      bottom: -50px;
    }
    img[alt="spike-right"] {
      height: 50px;
    }
  }
}

.section-2 {
  flex-direction: row-reverse;

  .left {
    padding-right: 0;

    .icon {
      position: absolute;
      top: 70%;
      left: -90px;
      height: 160px;
      width: auto;
    }
  }

  .right {
    padding-right: 60px;
    .bottom {
      @include flex($jc: flex-start, $ai: flex-end);
      margin-top: 10px;
      h3 {
        @include heading2();
        font-size: 5rem;
        color: var(--secondary-text);
        flex: 0.8;
        line-height: 50px;
      }
      p {
        @include para($fw: 500);
        color: var(--secondary-text);
        flex: 1.4;
      }
    }
  }

  .leaf-icon-left {
    top: -140px;
  }

  @media (max-width: 800px) {
    flex-direction: column;
    img[alt="cross-icon"] {
      display: none;
    }
  }
}

.section-3 {
  flex-direction: row-reverse;
  .left {
    padding-right: 0;

    .icon {
      position: absolute;
      top: 75%;
      left: -150px;
      height: 100px;
      width: auto;
    }
  }

  .right {
    padding-right: 60px;
    // overflow-y: visible;

    img[alt="quad-icon"] {
      position: absolute;
      top: -100px;
      right: 200px;
      width: 80px;
      height: auto;
    }

    .desc {
      margin: 10px 0;
      li {
        @include flex($jc: flex-start);
        img {
          height: 20px;
          width: auto;
          margin-right: 12px;
        }
        p {
          @include para();
          color: var(--secondary-text);
        }
      }
    }

    .bottom {
      @include flex($jc: flex-start);
      margin: 15px 0 0 -17px;
      img {
        width: 160px;
        height: auto;
        padding-right: 20px;
        border-right: 1px solid #7e7e7e;
      }
      p {
        @include para($fw: 500);
        color: var(--secondary-text);
        padding-left: 20px;
        width: 50%;
      }
    }
  }

  .leaf-icon-right {
    top: -200px;
  }

  @media (max-width: 800px) {
    flex-direction: column;
  }
}

.section-4 {
  .left {
    .icon {
      top: 30%;
    }
  }
  .right {
    span {
      display: block;
      @include para();
      color: var(--secondary-text);
      line-height: 30px;
    }
    .bottom {
      @include flex($jc: flex-start);

      img {
        width: 140px;
        margin-left: 10px;
      }

      button {
        @include button($bgc: #000);
        border: 1px solid #000;
        border-radius: 1px;
        margin-top: 10px;
      }
    }
  }

  .leaf-icon-left {
    top: -200px;
  }
}
