@import "../../mixins.scss";

.navigation-menu {
  width: 100%;
  background-color: #000;
  position: relative;
  .main {
    width: 85%;
    color: var(--primary-text);
    @include flex($jc: space-between);
    margin: auto;
    .desktop-links {
      @include flex($jc: space-between);
      li {
        padding: 0 20px;

        &:first-child {
          padding-left: 0;
        }

        a {
          font-size: 1.6rem;
          font-family: "Jost", sans-serif;
          font-weight: 400;
        }
      }
    }

    .mobile-links {
      transition: var(--basic);
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      background-color: #222;
      width: 100%;
      z-index: 7;
      overflow: hidden;
      height: 0;
      li {
        padding: 27px 0;
        color: var(--primary-text);
        border-bottom: 1px solid #ffffff81;
        width: 100%;
        position: relative;

        a {
          position: absolute;
          bottom: 0;
          left: 0;
          height: 100%;
          width: 100%;
          font-size: 1.6rem;
          padding: 16px 20px;
          width: 100vw;
          font-weight: 400;
          font-family: "Jost", sans-serif;
        }
      }
    }

    .show{
      transition: var(--basic);
      height: 325px;
    }

    .call-btn {
      @include flex($jc: flex-end);
      background-color: var(--primary-color);
      padding: 5px 15px;
      position: relative;
      p {
        @include para($fw: 600);
        margin-right: 20px;
      }
      img {
        width: 45px;
        height: 45px;
        z-index: 1;
      }

      &:after {
        content: "(770) 422-0211";
        position: absolute;
        right: 0;
        top: 0;
        height: 200px;
        width: 70px;
        background-color: var(--primary-color);
        writing-mode: vertical-rl;
        text-orientation: mixed;
        transform: scale(-1);
        @include flex($jc: flex-start);
        padding-top: 20px;
        @include para($fw: 700);
      }
    }

    .nav-icons {
      z-index: 8;
      display: none;
      img {
        padding: 14px 12px;
        height: 40px;
        width: auto;
        background-color: var(--primary-color);
        display: block;
      }

      img[alt="cut"]{
        padding: 10px 12px;
        height: 40px;
      }
    }
  }

  @media (max-width: 930px) {
    .main {
      .desktop-links {
        display: none;
      }
      .mobile-links {
        display: block;
      }

      .call-btn {
        p {
          margin-right: 15px;
          font-size: 1.3rem;
        }
        img {
          width: 30px;
          height: 30px;
        }
  
        &:after {
          visibility: hidden;
        }
      }

      .nav-icons {
        display: block;
      }
    }
  }
}