// font-sizes
@mixin heading1() {
  font-weight: 400;
  font-size: 8rem;
  font-family: "Oswald", sans-serif;

  @media (max-width: 1130px) {
    font-size: 5rem;
  }

  @media (max-width: 700px) {
    font-size: 3rem;
  }
}

@mixin heading2() {
  font-weight: 400;
  font-size: 3.5rem;
  font-family: "Oswald", sans-serif;

  @media (max-width: 700px) {
    font-size: 2.8rem;
  }
}

@mixin para($fw: 400) {
  font-weight: $fw;
  font-size: 1.6rem;
  font-family: "Jost", sans-serif;
}

@mixin label($fw: 400) {
  font-weight: $fw;
  font-size: 2.2rem;
  font-family: "Jost", sans-serif;
  text-transform: capitalize;
  @media (max-width: 700px) {
    font-size: 1.6rem;
  }
}

@mixin flex($jc: center, $ai: center) {
  display: flex;
  justify-content: $jc;
  align-items: $ai;
}

@mixin button($bgc: #ec1c24, $hbgc: #fff, $w: 160px, $c: #fff, $hc: #000) {
  @include flex;
  border: none;
  transition: all .3s;
  a {
    padding: 10px 0;
    cursor: pointer;
    transition: all .3s;
    width: $w;
    background-color: $bgc;   
    border: none;
    border-radius: 1px;

    @include para();
    color: $c;
    text-transform: capitalize
  }

  &:hover {
    a {
      background-color: $hbgc;
      color: $hc;
      font-weight: 600;
    }
  }
}
