@import "../../mixins.scss";

.treatment-card {
  min-width: 300px;
  max-width: 300px;
  min-height: 424px;
  max-height: 424px;
  background: #fff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  border-top: 2px solid var(--primary-color);
  border-bottom: 2px solid var(--primary-color);
  padding: 10px 17px;
  text-align: center;
  letter-spacing: 1.1px;
  @include flex();
  flex-direction: column;
  margin: 30px;
  .image {
    height: 200px;
    @include flex();
    img {
      width: 90%;
      height: auto;
    }
  }

  .content {
    height: 155px;
    @include flex($jc: space-between);
    flex-direction: column;
    h3 {
      @include heading2();
      color: var(--secondary-text);
    }
    p {
      @include para();
      color: var(--secondary-text);
      margin: 0 0 5px 0;
    }
    a {
      @include para($fw: 600);
      text-decoration: underline;
      color: var(--primary-color);
      transition: var(--advance);
      &:hover {
        font-size: 1.7rem;
      }
    }
  }

  @media (max-width: 700px) {
    &:first-child {
      margin-top: 0;
    }
  }
}
