@import "../../mixins.scss";

.blog-detail-page {
  padding: 0 10%;
  position: relative;

  .title {
    @include heading2();
    color: var(--secondary-text);
    margin-top: 15px;
  }

  .info {
    margin: 30px 0;
    @include flex($jc: flex-start);
    .left {
      margin-right: 30px;
      .name {
        @include flex($jc: flex-start, $ai: flex-end);
        img {
          height: 30px;
          width: auto;
          margin-right: 7px;
        }
        p {
          @include para($fw: 600);
          color: #333;
          text-transform: capitalize;
        }
      }

      .date {
        margin: 7px 0 0 3px;
        @include para($fw: 600);
        color: var(--secondary-text);
        span {
          color: var(--primary-color);
          @include para($fw: 600);
        }
      }
    }

    .right {
      margin-top: 6px;
      p {
        @include para($fw: 600);
        color: #333;
        &:nth-child(2) {
          margin-top: 7px;
          font-weight: 500;
        }
      }
    }
  }

  .content {
    .heading {
      @include para($fw: 600);
      color: #222;
      max-width: 60%;
    }

    ul {
      margin: 20px 0 20px 20px;
      li {
        position: relative;
        margin: 15px 0;
        @include para($fw: 400);
        color: #222;
        span {
          @include para($fw: 600);
        }

        &:before {
          position: absolute;
          content: "";
          top: 6px;
          left: -20px;
          height: 10px;
          width: 10px;
          background-color: var(--primary-color);
          border-radius: 100%;
        }
      }
    }

    .closing-line-one,
    .closing-line-two {
      @include para($fw: 600);
      color: #333;
    }
    .closing-line-two {
      margin-bottom: 60px;
    }
  }

  .bg-pattern {
    background: url("../../assets/images/leaves-pattern.svg") center center
      no-repeat;
    background-size: cover;
    position: absolute;
    top: 50px;
    left: 0;
    height: 850px;
    width: 100%;
    z-index: -5;
  }

  .bg-pattern2 {
    background: url("../../assets/images/leaves-pattern.svg") center center
      no-repeat;
    background-size: cover;
    position: absolute;
    top: 850px;
    left: 0;
    height: 850px;
    width: 100%;
    z-index: -5;
  }

  img[alt="quad-icon"] {
    position: absolute;
    left: 30px;
    bottom: 650px;
    width: 60px;
    height: auto;
  }

  img[alt="spikes-icon"] {
    position: absolute;
    top: 250px;
    right: 20px;
    width: 80px;
    height: auto;
  }

  @media (max-width: 700px) {
    padding: 0 3%;
    .info {
      flex-direction: column;
      align-items: flex-start;
    }

    img[alt="quad-icon"] {
      display: none;
    }

    img[alt="spikes-icon"] {
      display: none;
    }
  }
}
