@import "../../mixins.scss";

.header {
  margin: auto;
  width: 85%;
  @include flex($jc: space-between);
  padding: 15px 0;
  z-index: 10;
  .logo {
    flex: 1;
    @include flex();
    img {
      width: 334px;
      height: auto;
    }
  }

  .address,
  .number {
    @include flex($jc: flex-start);
    flex: 1;
    flex-grow: 0.6;
    width: 10px;
    color: var(--secondary-text);
    img {
      width: 53px;
      height: 53px;
      margin-right: 3px;
    }
    p,a {
      font-size: 1.6rem;
      font-family: "Jost", sans-serif;
      font-weight: 400;
    }
  }

  .number {
    @include flex($jc: flex-end);
  }

  @media (max-width: 900px) {
    width: 95%;
    .logo {
      img {
        width: 250px;
      }
    }
    .address,
    .number {
      img {
        width: 43px;
        height: 43px;
      }
      p, a {
        font-size: 1.4rem;
        font-family: "Jost", sans-serif;
        font-weight: 400;
      }
    }
  }

  @media (max-width: 700px) {
    .logo {
      img {
        width: 150px;
      }
    }
    .address,.number{
      flex-grow: 1;
    }
  }

  @media (max-width: 550px) {
    flex-direction: row-reverse;
    .address,
    .number {
      img {
        width: 23px;
        height: 23px;
        // display: none;
      }
      p, a {
        font-size: 1rem;
        font-family: "Jost", sans-serif;
        font-weight: 400;
        line-height: 13px;
      }
    }
    .number{
      display: none;
    }
  }
}
