@import "../../mixins.scss";

.testimonials {
  position: relative;
  .t-section {
    position: relative;
    flex-direction: row-reverse;
    .left {
      padding-right: 0;
    }
    .right {
      padding-right: 60px;
      .mid {
        @include para($fw: 600);
        text-decoration: underline;
        margin: 12px 0;
      }
    }

    .bg-pattern {
      background: url("../../assets/images/leaves-pattern.svg") center center
        no-repeat;
      background-size: cover;
      position: absolute;
      top: 0;
      left: 0;
      height: 850px;
      width: 100%;
      z-index: -5;
    }

    img[alt="quad-icon"] {
      position: absolute;
      left: 30px;
      bottom: -250px;
      width: 60px;
      height: auto;
    }

    img[alt="spikes-icon"] {
      position: absolute;
      top: -55px;
      right: 80px;
      width: 80px;
      height: auto;
    }
  }

  .all-testimonials {
    padding: 0 10%;
    .testimonial-card {
      width: 75%;
      margin: 30px 0;
      padding-bottom: 30px;
      border-bottom: 1px solid #d9d9d9;
    }
  }

  @media (max-width:800px) {
    .t-section{
      flex-direction: column !important;
      img[alt="quad-icon"]{
        top: 100%;
        width: 50px;
      }
    }

    .all-testimonials{
      .testimonial-card{
        width: 100%;
      }
    }
  }
}
