@import "../../mixins.scss";

.contact {
  .c-section {
    .bg-pattern {
      background: url("../../assets/images/leaves-pattern.svg") center center
        no-repeat;
      background-size: cover;
      position: absolute;
      top: -50px;
      left: 0;
      height: 850px;
      width: 100%;
      z-index: -5;
    }

    img[alt="quad-icon"] {
      position: absolute;
      left: 30px;
      bottom: -255px;
      width: 60px;
      height: auto;
    }

    img[alt="spikes-icon"] {
      position: absolute;
      top: -55px;
      right: 80px;
      width: 80px;
      height: auto;
    }
  }

  .contact-form {
    padding: 0 10%;
    @include flex($jc: space-between);
    margin-bottom: 90px;
    margin-top: 40px;

    .details {
      padding-right: 35px;
      flex: 0.4;
      border-right: 1px solid #999999;

      div,
      a {
        text-align: center;
        margin: 30px 0;
        img {
          @include flex();
          width: 50px;
          height: auto;
          margin: auto;
          padding: 12px 12px;
          background-color: #fff;
          border-radius: 100%;
          box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.15);
        }
        p {
          @include para();
          color: #333;
        }
        &:nth-child(3) {
          img {
            padding: 12px 16px;
          }
        }
      }
    }

    .form {
      flex: 1.6;
      padding-left: 35px;
      h3 {
        @include heading2();
        color: var(--secondary-text);
        text-transform: uppercase;
      }
      p {
        @include para();
        color: var(--secondary-text);
        margin: 8px 0;
      }
      form {
        input {
          display: block;
          background: transparent;
          width: 100%;
          margin: 15px 0;
          padding: 10px 5px;
          border: none;
          border-bottom: 1px solid #d9d9d9;
          @include para();
          font-size: 1.2rem;
          &:focus {
            outline: none;
          }
        }
        button {
          @include button();
        }
      }
    }
  }

  @media (max-width: 800px) {
    .contact-form {
      flex-direction: column;
      .details {
        border: none;
        padding: 0;
        margin-bottom: 30px;
        div,
        a {
          img {
            margin-bottom: 15px;
          }
        }
      }
      .form {
        padding: 0;
      }
    }
  }
}
