@import "../../mixins.scss";

.smyrna {
  // padding: 0 10%;

  .head {
    text-align: center;
    padding: 0 10%;
    h1 {
      @include heading2();
      color: var(--secondary-text);
    }
    .description {
      @include para();
      color: var(--secondary-text);
      margin: 10px auto 60px auto;
      span {
        display: block;
        @include para();
        color: var(--secondary-text);
        margin: 15px 0;
      }
    }
  }

  .our-treatments-section {
    width: 100%;
    padding: 0 2.5%;
    margin: auto;
    text-align: center;
    margin-bottom: 60px;
    position: relative;
    overflow: visible;

    .bg-pattern {
      background: url("../../assets/images/leaves-pattern.svg") center center
        no-repeat;
      background-size: cover;
      position: absolute;
      top: -15px;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: -5;
    }

    .label {
      @include label();
      color: var(--primary-color);
      text-transform: capitalize;
    }

    .heading {
      @include heading2();
      color: var(--secondary-text);
      text-transform: uppercase;
    }

    .sect {
      @include flex();
      margin: 30px 0 30px 0;
      flex-wrap: wrap;

      .image-card {
        background: url("../../assets/images/treatment-img.jpg") center center
          no-repeat;
        border-top: 2px solid var(--primary-color);
        border-bottom: 2px solid var(--primary-color);
        min-width: 300px;
        max-width: 300px;
        min-height: 424px;
        max-height: 424px;
      }
    }

    button {
      @include button($hc: var(--primary-color));
      border: 1px solid var(--primary-color);
      margin: auto;
      &:hover {
        border: 1px solid var(--primary-color);
      }
    }

    .treatment-card {
      min-height: 500px;
      a {
        display: none;
      }
    }
  }

  .why-choose-us {
    h3 {
      @include heading2();
      color: var(--secondary-text);
      text-align: center;
      padding: 0 10%;
    }
    .description {
      @include para();
      color: var(--secondary-text);
      margin: 10px auto 60px auto;
      text-align: center;
      padding: 0 10%;
    }

    .section {
      .right {
        div {
          margin: 25px 0;
          .title {
            @include heading2();
            font-size: 2.2rem;
            color: var(--secondary-text);
            text-transform: uppercase;
            margin: 0;
            line-height: 29px;
            margin-bottom: 2px;
          }
          .desc {
            @include para();
            color: var(--secondary-text);
            line-height: 22px;
          }
        }
      }
    }
  }
}
