@import "../../mixins.scss";

.service-card2{
    margin: 30px 15px;
    img{
        width: 350px;
        height: auto;
    }
    h4{
        @include heading2();
        color: var(--secondary-text);
    }
    .price{
        @include label($fw: 500);
        color: var(--primary-color);
    }
}